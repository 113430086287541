// Created By Trevor Colby
// IMPORTANT: This file should be identical for every mountain
// any configuration should occur in './Configuration.js'
import configuration from '../screens/Configuration';

const { devURL, prodURL, jawgToken } = configuration;

export const ROOT_URL = process.env.NODE_ENV === 'development'
  ? devURL : prodURL;

// These are for retrieving lat/long based hydrant elevations
// const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/';
// export const JAWG_URI = process.env.NODE_ENV === 'production' ? 'https://api.jawg.io/elevations' : `${CORS_PROXY}https://api.jawg.io/elevations`;
export const JAWG_URI = process.env.NODE_ENV === 'production' ? 'https://api.jawg.io/elevations' : 'https://api.jawg.io/elevations';
// export const JAWG_ACCESS_TOKEN = 's7iSO998IpSBA2Ktgs541UXt2WcXt30qIHBRDzd2aQWOGLCFEJszM5tU6fHBtjYJ';
// Login to JAWG Lab to See Usage Statistics:
export const JAWG_ACCESS_TOKEN = jawgToken;
export const FEET_PER_METER = 3.280839895;

export const TOKEN_STRING = 'userAuthToken';

export const ActionTypes = {
  FETCH_LOCATIONS_SUCCESS: 'FETCH_LOCATIONS_SUCCESS',
  FETCH_LOCATION_SUCCESS: 'FETCH_LOCATION_SUCCESS',
  FETCH_LOCATION_ERROR: 'FETCH_LOCATION_ERROR',

  FETCH_ALL_EQUIPMENT_SUCCESS: 'FETCH_ALL_EQUIPMENT_SUCCESS',
  FETCH_ALL_VALVES_SUCCESS: 'FETCH_ALL_VALVES_SUCCESS',
  FETCH_EQUIPMENT_SUCCESS: 'FETCH_EQUIPMENT_SUCCESS',
  FETCH_EQUIPMENT_ERROR: 'FETCH_EQUIPMENT_ERROR',

  FETCH_ALL_GUNS_SUCCESS: 'FETCH_ALL_GUNS_SUCCESS',
  FETCH_GUN_SUCCESS: 'FETCH_GUN_SUCCESS',
  FETCH_GUNS_ERROR: 'FETCH_GUNS_ERROR',
  UPDATE_GUN_AVAILABLE_SUCCESS: 'UPDATE_GUN_AVAILABLE_SUCCESS',
  ADD_GUN_SUCCESS: 'ADD_GUN_SUCCESS',
  UPDATE_GUN_SUCCESS: 'UPDATE_GUN_SUCCESS',
  ADD_GUN_MODEL_SUCCESS: 'ADD_GUN_MODEL_SUCCESS',
  DELETE_GUN_MODEL_SUCCESS: 'DELETE_GUN_MODEL_SUCCESS',
  UPDATE_GUN_MODEL_SUCCESS: 'UPDATE_GUN_MODEL_SUCCESS',

  FETCH_ALL_TRAILS_SUCCESS: 'FETCH_ALL_TRAILS_SUCCESS',
  FETCH_ALL_TRAILS_W_GEO_SUCCESS: 'FETCH_ALL_TRAILS_W_GEO_SUCCESS',
  FETCH_ALL_AUTOTRAILS_SUCCESS: 'FETCH_ALL_AUTOTRAILS_SUCCESS',
  FETCH_ALL_AUTOTRAILS_IO_SUCCESS: 'FETCH_ALL_AUTOTRAILS_IO_SUCCESS',
  FETCH_TRAIL_SUCCESS: 'FETCH_TRAIL_SUCCESS',
  FETCH_AUTOTRAIL_SUCCESS: 'FETCH_AUTOTRAIL_SUCCESS',
  FETCH_AUTOTRAIL_IO_SUCCESS: 'FETCH_AUTOTRAIL_IO_SUCCESS',
  ADD_AUTO_TRAIL_IO_VALUE: 'ADD_AUTO_TRAIL_IO_VALUE',
  DELETE_AUTO_TRAIL_IO_VALUE: 'DELETE_AUTO_TRAIL_IO_VALUE',
  FETCH_TRAILS_ERROR: 'FETCH_TRAILS_ERROR',
  AUTO_HYDRANT_ERROR: 'AUTO_HYDRANT_ERROR',

  FETCH_ALL_HYDRANT_MODELS_SUCCESS: 'FETCH_ALL_HYDRANT_MODELS_SUCCESS',
  FETCH_ALL_HYDRANTS_SUCCESS: 'FETCH_ALL_HYDRANTS_SUCCESS',
  FETCH_HYDRANT_SUCCESS: 'FETCH_HYDRANT_SUCCESS',
  FETCH_HYDRANT_IO_SUCCESS: 'FETCH_HYDRANT_IO_SUCCESS',
  ADD_HYDRANT_SUCCESS: 'ADD_HYDRANT_SUCCESS',
  DELETE_HYDRANT_SUCCESS: 'DELETE_HYDRANT_SUCCESS',
  // EDIT_HYDRANT_SUCCESS: 'EDIT_HYDRANT_SUCCESS',
  UPDATE_HYDRANT_SUCCESS: 'UPDATE_HYDRANT_SUCCESS',
  FETCH_HYDRANT_ERROR: 'FETCH_HYDRANT_ERROR',
  SELECT_HYDRANT: 'SELECT_HYDRANT',
  SELECT_TRAIL: 'SELECT_TRAIL',
  SELECT_AUTO_TRAIL: 'SELECT_AUTO_TRAIL',
  ADD_TRAIL_SUCCESS: 'ADD_TRAIL_SUCCESS',
  DELETE_TRAIL_SUCCESS: 'DELETE_TRAIL_SUCCESS',
  EDIT_TRAIL_SUCCESS: 'EDIT_TRAIL_SUCCESS',

  NOTE_ADD_CHANGE_SUCCESS: 'NOTE_ADD_CHANGE_SUCCESS',
  FETCH_ALL_NOTES_SUCCESS: 'FETCH_ALL_NOTES_SUCCESS',
  FETCH_GLOBAL_NOTES_SUCCESS: 'FETCH_GLOBAL_NOTES_SUCCESS',
  FETCH_NOTES_ERROR: 'FETCH_NOTES_ERROR',
  // SET_NOTE_TEXT_SUCCESS: 'SET_NOTE_TEXT_SUCCESSS',

  AUTH_USER: 'AUTH_USER',
  AUTH_NEED_2FA: 'AUTH_NEED_2FA',
  DEAUTH_USER: 'DEAUTH_USER',
  AUTH_ERROR: 'AUTH_ERROR',
  SET_DASHBOARD_USE_CELSIUS: 'SET_DASHBOARD_USE_CELSIUS',
  SET_DASHBOARD_USE_CELSIUS_ERROR: 'SET_DASHBOARD_USE_CELSIUS_ERROR',

  FETCH_SETTINGS_SUCCESS: 'FETCH_SETTINGS_SUCCESS',
  UPDATE_SETTINGS_SUCCESS: 'UPDATE_SETTINGS_SUCCESS',
  SETTINGS_ERROR: 'SETTINGS_ERROR',
  FETCH_GUN_MODELS_SUCCESS: 'FETCH_GUN_MODELS_SUCCESS',
  GUN_MODELS_ERROR: 'GUN_MODELS_ERROR',

  FETCH_ALL_IOVALUES_SUCCESS: 'FETCH_ALL_IOVALUES_SUCCESS',
  SET_IOVALUE_SUCCESS: 'SET_IOVALUE_SUCCESS',
  FETCH_TOTAL_GALLONS_SUCCESS: 'FETCH_TOTAL_GALLONS_SUCCESS',
  FETCH_SNOWMAKING_HOURS_SUCCESS: 'FETCH_SNOWMAKING_HOURS_SUCCESS',
  FETCH_SNOWMAKING_TRAILS_SUCCESS: 'FETCH_SNOWMAKING_TRAILS_SUCCESS',
  FETCH_AVERAGE_SEI_SUCCESS: 'FETCH_AVERAGE_SEI_SUCCESS',
  FETCH_TREND_DATASET_SUCCESS: 'FETCH_TREND_DATASET_SUCCESS',
  FETCH_TRENDS_SUCCESS: 'FETCH_TRENDS_SUCCESS',
  ADD_TREND_SUCCESS: 'ADD_TREND_SUCCESS',
  UPDATE_TREND_SUCCESS: 'UPDATE_TREND_SUCCESS',
  DELETE_TREND_SUCCESS: 'DELETE_TREND_SUCCESS',
  ADD_TRENDIOVALUE_SUCCESS: 'ADD_TRENDIOVALUE_SUCCESS',
  DELETE_TRENDIOVALUE_SUCCESS: 'DELETE_TRENDIOVALUE_SUCCESS',
  TREND_ERROR: 'FETCH_TREND_ERROR',

  UPDATE_NAV_STATE: 'UPDATE_NAV_STATE',
  FETCH_ALL_NAV_STATS: 'FETCH_ALL_NAV_STATS',
  FETCH_NAV_STATS_ERROR: 'FETCH_NAV_STATS_ERROR',

  FETCH_ALL_WEATHER_STATIONS_SUCCESS: 'FETCH_ALL_WEATHER_STATIONS_SUCCESS',
  FETCH_WEATHER_STATION_SUCCESS: 'FETCH_WEATHER_STATION_SUCCESS',
  FETCH_WEATHER_STATION_ERROR: 'FETCH_WEATHER_STATION_ERROR',

  FETCH_FLOW_ZONES_SUCCESS: 'FETCH_FLOW_ZONES_SUCCESS',
  FETCH_TEMPERATURE_ZONES_SUCCESS: 'FETCH_TEMPERATURE_ZONES_SUCCESS',
  FETCH_PRESSURE_ZONES_SUCCESS: 'FETCH_PRESSURE_ZONES_SUCCESS',
  FETCH_ZONE_IO_VALUES_SUCCESS: 'FETCH_ZONE_IO_VALUES_SUCCESS',
  ADD_ZONE_IO_VALUE_SUCCESS: 'ADD_ZONE_IO_VALUE_SUCCESS',
  DELETE_ZONE_IO_VALUE_SUCCESS: 'DELETE_ZONE_IO_VALUE_SUCCESS',
  EDIT_ZONE_IO_VALUE_SUCCESS: 'EDIT_ZONE_IO_VALUE_SUCCESS',
  ADD_ZONE_SUCCESS: 'ADD_ZONE_SUCCESS',
  DELETE_ZONE_SUCCESS: 'DELETE_ZONE_SUCCESS',
  EDIT_ZONE_SUCCESS: 'EDIT_ZONE_SUCCESS',
  FETCH_ZONES_ERROR: 'FETCH_ZONES_ERROR',

  FETCH_PLCS_SUCCESS: 'FETCH_PLCS_SUCCESS',
  FETCH_PLC_SUCCESS: 'FETCH_PLC_SUCCESS',
  FETCH_PLC_ERROR: 'FETCH_PLC_ERROR',

  FETCH_ELEVATION_SUCCESS: 'FETCH_ELEVATIONS_SUCCESS',
  FETCH_ELEVATION_ERROR: 'FETCH_ELEVATIONS_ERROR',

  FETCH_ALL_TRACKER_EQUIPMENT_SUCCESS: 'FETCH_ALL_TRACKER_EQUIPMENT_SUCCESS',
  FETCH_TRACKER_EQUIPMENT_SUCCESS: 'FETCH_TRACKER_EQUIPMENT_SUCCESS',
  FETCH_ALL_DEFENDERS_SUCCESS: 'FETCH_ALL_DEFENDERS_SUCCESS',
  ADD_AUTO_HYDRANT_SUCCESS: 'ADD_AUTO_HYDRANT_SUCCESS',
  DELETE_AUTO_HYDRANT_SUCCESS: 'DELETE_AUTO_HYDRANT_SUCCESS',
  FETCH_TRACKER_EQUIPMENT_ERROR: 'FETCH_TRACKER_EQUIPMENT_ERROR',

  FETCH_TRACKER_IOVALUES_SUCCESS: 'FETCH_TRACKER_IOVALUES_SUCCESS',
};
