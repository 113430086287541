// Created By Trevor Colby
import React, {
  useEffect,
  // useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
// import {
//   Map, View, Feature,
// } from 'ol';
// import {
//   Tile as TileLayer,
//   Vector as VectorLayer,
// } from 'ol/layer';
// import TileState from 'ol/TileState';
// import { XYZ, Vector as VectorSource } from 'ol/source';
// import {
//   Point,
// } from 'ol/geom';
// import * as Proj from 'ol/proj';
// import 'ol/ol.css';

import {
  DashWeather, GunsOn, SEI, ProductionOverview,
} from 'isno/lib/components/dashboard';
import {
  fetchAllWeatherStations,
  fetchAllIOValues,
  setIOValueTrends,
} from '../actions';
import Background from '../static/background.png';
// import configuration from './Configuration';

// Configuration values pulled in from the './Configuration.js' file
// const {
//   padding, rotationFactor, center, initialZooms, defaultCenterPoints,
// } = configuration;

// const initialZoom = initialZooms[0];
// const defaultCenterPoint = defaultCenterPoints[0];

// let map, defaultCenterLayer;

// const defaultCenterSource = new VectorSource();

// Function used to load our google maps tiles, we use this instead of the default
// loader to prevents cross site cookie errors, may need to attach additional headers in the future
// const customLoader = (tile, src) => {
//   const xhr = new XMLHttpRequest();
//   xhr.responseType = 'blob';
//   xhr.addEventListener('loadend', function load(evt) {
//     const data = this.response;
//     if (data !== undefined) {
//       tile.getImage().src = URL.createObjectURL(data); // eslint-disable-line no-param-reassign
//     } else {
//       tile.setState(TileState.ERROR);
//     }
//   });
//   xhr.addEventListener('error', () => {
//     tile.setState(TileState.ERROR);
//   });
//   xhr.open('GET', src);
//   // xhr.setRequestHeader('foo', 'bar'); // Example of how to set header
//   xhr.send();
// };

const DashboardOverview = (props) => {
  const theme = useTheme();
  const styles = stylesFromTheme(theme, { navbarClosed: props.navbarClosed });

  let shortIntervalId;
  const shortInterval = () => {
    shortIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchAllWeatherStations(),
        props.fetchAllIOValues(),
      ];
      await Promise.all(promises);
      // Need more involved reducer to get condensed to work
      // props.fetchAllIOValuesCondensed();
      shortIntervalId = setTimeout(shortInterval, 3000);
    }, 3000);
  };

  useEffect(() => {
    props.fetchAllIOValues();
    props.fetchAllWeatherStations();
    shortInterval();
    return () => {
      clearTimeout(shortIntervalId);
    };
  }, []);

  // useEffect(() => {
  // if (defaultCenterPoint) {
  //   defaultCenterSource.addFeatures([new Feature(new Point(Proj.fromLonLat(defaultCenterPoint)))]);
  // }

  // defaultCenterLayer = new VectorLayer({
  //   name: 'defaultCenterLayer',
  //   source: defaultCenterSource,
  //   visible: false,
  // });

  // const googleMapsTileSource = new XYZ({
  //   url: 'https://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}',
  //   maxZoom: initialZoom,
  // });
  // googleMapsTileSource.setTileLoadFunction(customLoader);

  // const tiles = new TileLayer({
  //   source: googleMapsTileSource,
  //   preload: 10,
  // });

  // Create map with initial setup
  // map = new Map({
  //   target: 'overviewMap',
  //   layers: [
  //     tiles,
  //     defaultCenterLayer,
  //   ],
  //   view: new View({
  //     center,
  //     zoom: initialZoom,
  //     ...(rotationFactor && {
  //       rotation: rotationFactor,
  //     }),
  //   }),
  //   controls: [],
  //   interactions: [],
  // });

  // if (!defaultCenterSource.isEmpty()) {
  //   map.getView().fit(defaultCenterSource.getExtent(),
  //     {
  //       size: map.getSize(),
  //       ...(padding && {
  //         padding,
  //       }),
  //     });
  // }
  // map.getView().setZoom(initialZoom);

  // Handle our map resizing
  // window.onresize = () => {
  //   setTimeout(() => { map.updateSize(); }, 500);
  // };
  //   return () => {};
  // }, []);

  return (
    <div css={styles.background}>
      {/* <div id="overviewMap" css={[styles.map]} /> */}
      {/* <img alt="background" src={Background} css={heightRatio > 2.77 ? css`width: 100vw;` : css`height: 100vh;`} /> */}
      <div css={[styles.locationsContainer, styles.hideScrollbar]}>
        <div css={[styles.statuses]}>
          <ProductionOverview
            user={props.auth?.user}
            setIOValue={props.setIOValueTrends}
            mountains={[
              {
                name: 'Jiminy Peak',
                day: 240,
                year: 242,
                target: 2,
              },
              {
                name: 'Cranmore',
                day: 315,
                year: 317,
                target: 3,
              },
              {
                name: 'Bromley',
                day: 152,
                year: 154,
                target: 1,
              },
            ]}
            ioValues={props.trends.IOValues}
          />
          <GunsOn
            ioValues={props.trends.IOValues}
          />
          <SEI
            ioValues={props.trends.IOValues}
          />
          {/* <DashWaterPumped
            ioValues={props.trends.IOValues}
            mountain="Snowmass"
          /> */}
          <DashWeather
            weatherStations={props.weatherStations?.weatherStations}
            celsius={props.settings?.dashboardUseCelsius}
          />
          {/* <DashPower
            ioValues={props.trends.IOValues}
            mountain="Snowmass"
          /> */}
        </div>
        <div css={css`display: flex;`} />
      </div>
    </div>
  );
};

const stylesFromTheme = (theme, props) => {
  return {
    background: css`
      flex: 1;
      display: flex;
      flex-direction: row;
      position: relative;
      overflow: hidden;
      height: 100vh;
      background-image: url(${Background});
      background-size: cover;
  `,
    map: css`
      height: 100vh;
      width: 100%;
      position: relative;
    `,
    statuses: css`
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-end;
      align-items: flex-start;
      height: fit-content;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding: 0px;
        justify-content: center;  
      }
    `,
    locationsContainer: css`
      position: absolute;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      top: 0px;
      right: 0px;
      height: 100%;
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        max-width: calc(100vw - 16px);
      }
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      // -ms-overflow-style: none;  /* Internet Explorer 10+ */
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
    `,
  };
};

DashboardOverview.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      roles: PropTypes.string,
    }),
  }).isRequired,

  // IOValues: PropTypes.shape({}).isRequired,
  trends: PropTypes.shape({
    IOValues: PropTypes.shape({
    }),
  }).isRequired,

  weatherStations: PropTypes.shape({
    weatherStations: PropTypes.shape({}),
  }).isRequired,

  settings: PropTypes.shape({
    dashboardUseCelsius: PropTypes.bool,
  }).isRequired,

  fetchAllWeatherStations: PropTypes.func.isRequired,
  fetchAllIOValues: PropTypes.func.isRequired,
  setIOValueTrends: PropTypes.func.isRequired,
  navbarClosed: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  trails: state.trails,
  trends: state.trends,
  weatherStations: state.weatherStations,
  navbarClosed: state.nav.navbarClosed,
  settings: state.settings,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchAllWeatherStations,
  fetchAllIOValues,
  setIOValueTrends,
})(DashboardOverview);
