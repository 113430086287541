// Aspen Dashboard Configuration File
// Template Created By: Trevor Colby
// 9/21/2021

// Values to fine tune for a new mountain setup
// Used by: Tracker Screen & Overview Screen
// - center: center of the view, shouldn't change from mountain to mountain (included just in case there is a mountain with special configuration needs)
// - defaultCenterPoint: The coordinates of the default center location of the map. Only if there are no hydrants present.
//    You can get this value by using the TrackerMapper tool https://jolavb.github.io/Sno_Demo to place a hydrant at the center of the desired resort, exporting a GeoJson of
//    the hydrants (make sure to uncheck the exclude orphans options), opening the exported file in a text editor, and copying the coordinates of the hydrant. (format: [number, number])
// - minZoomLimit: how far you can zoom out
// - maxZoomLimit: how far you can zoom in
// - initialZoom: starting zoom level, should be between min/max zoom
// - padding: tweaks the map's view center to accomdate for outside elements that are covering viewspace  (format: [200, 200, 200, 200])
// - extentFactors: variable to control how far user can scroll, each value is used as a multiplier so use decimals for division (format: [6.0, 6.0, 6.0, 6.0])
// - rotationFactor: orient the mountain (value is in radians i.e the Math.PI)
// - mapEditRole: User role that dictates if map edit options are displayed for the tracker and in settings
// - developerRole: User role that dictates if specific plant controls requiring developer access are rendered
// - devURL/prodURL: Base url for the backend api (NOTE: this needs to be updated appropriately for local deployments)
// - jawgToken: token to access the free jawg api that gives us elevations for hydrants. (NOTE: Eventually we will want a few of these in circulation to prevent having too many requests)

const configuration = {
  center: [0, 0],
  defaultCenterPoints: [
    [-106.869340, 39.178231], // overview
    [-73.286517, 42.549241], // Jiminy
    // [-106.86004007875454, 39.20489464759339], // Buttermilk
    [-71.097368, 44.057492], // Cranmore
    [-72.936487, 43.221805], // Bromley
  ],
  minZoomLimit: 13,
  maxZoomLimit: 20,
  initialZooms: [
    12, // Overview
    15.5, // Jiminy
    15, // Cranmore
    15.5, // Bromley
  ],
  padding: undefined,
  extentFactors: undefined,
  rotationFactors: [
    (Math.PI) / 1.5, // Overview
    (Math.PI) / 1.5, // Jimminy
    4.7, // Cranmomre
    5, // Bromley
  ],
  mapEditRole: 'MapEditor',
  developerRole: 'Developer',
  devURL: 'https://fairbank-dashboard.isno.io/api/v1.0',
  prodURL: 'https://fairbank-dashboard.isno.io/api/v1.0',
  jawgToken: 'TQ3T3df0pxRwdI4DQeXRRiHMYyVUPdLo69Ml4DFp0kn5oIwDXNFOHP6ZlWbRZIGS',
};

export default configuration;
