// Created By Trevor Colby
// IMPORTANT: This file should be identical for every mountain
// any configuration should occur in './Configuration.js'
import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { isEqual, startCase } from 'lodash';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';
import Select from 'react-select';
import 'react-tabs/style/react-tabs.css';
import { LanguageContext, Languages } from 'isno/lib/languages/LanguageContext';
// import { GunModels, AutoTrails, Zones } from 'isno/lib/components/settings';
import { LoadingIndicator } from 'isno/lib/components/displays';
import {
  setDashboardUnits,
} from '../actions';

import configuration from './Configuration';

let languageOptions;

// Configuration values pulled in from the './Configuration.js' file
const {
  mapEditRole,
} = configuration;

const SettingsScreen = (props) => {
  const { language } = useContext(LanguageContext);
  const theme = useTheme();
  const styles = stylesFromTheme(theme);
  const [currentTab, setCurrentTab] = useState(0);
  // const [initRequestsComplete, setInitRequestsComplete] = useState(false);
  // const [renderLoader, setRenderLoader] = useState(true);
  const [useCelsiusLoading, setUseCelsiusLoading] = useState(false);
  const [useCelsius, setUseCelsius] = useState(null);
  const currentTabRef = useRef(currentTab);
  // const [userRoles, setUserRoles] = useState(0);

  // let timedIntervalId;
  // const timedInterval = () => {
  //   timedIntervalId = setTimeout(() => {
  //     if (currentTabRef.current === 1) {
  //       props.fetchAllGuns();
  //     } else if (currentTabRef.current === 2) {
  //       props.fetchAllTrails();
  //       props.fetchAllAutoTrails();
  //       props.fetchAllAutoTrailsIOValues();
  //     } else if (currentTabRef.current === 3) {
  //       props.fetchTemperatureZones();
  //       props.fetchPressureZones();
  //       props.fetchFlowZones();
  //       props.fetchAllZoneIOValues();
  //       props.fetchAllIOValues();
  //       props.fetchAllTrackerIOValues();
  //     }
  //     timedIntervalId = setTimeout(timedInterval, 4000);
  //   }, 4000);
  // };

  // To start we fetch all of the data for all of the tabs.
  // After that we only fetch data for the current tab
  // const fetchAllInitialData = async () => {
  //   const requests = [
  //     props.fetchAllGuns(),
  //     props.fetchAllTrails(),
  //     props.fetchAllAutoTrails(),
  //     props.fetchAllAutoTrailsIOValues(),
  //     props.fetchTemperatureZones(),
  //     props.fetchPressureZones(),
  //     props.fetchFlowZones(),
  //     props.fetchAllZoneIOValues(),
  //     props.fetchAllIOValues(),
  //     props.fetchAllTrackerIOValues(),
  //   ];
  //   await Promise.all(requests);
  //   setInitRequestsComplete(true);
  // };

  // useEffect(() => {
  //   if (props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole)) {
  //     // Async function to asynchronously request all of our data but then wait for
  //     // all of the responses before setting 'initRequestsComplete' to true. We use
  //     // this to make sure we have all of the neccesary data before completing our page load.
  //     fetchAllInitialData();
  //     timedInterval();
  //   } else {
  //     // If the current user doesn't have access to advanced roles
  //     // then we don't need to make further requests
  //     setInitRequestsComplete(true);
  //   }

  //   return () => {
  //     clearTimeout(timedIntervalId);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (props.settings?.settings) {
  //     setLanguage(Languages[props.settings?.settings?.language]);
  //   }
  // }, [props.auth?.user?.useCelsius]);

  useEffect(() => {
    currentTabRef.current = currentTab;
  }, [currentTab]);

  useEffect(() => {
    if (!useCelsiusLoading) {
      const tempUseCelsius = {
        value: props.settings?.dashboardUseCelsius,
        label: props.settings?.dashboardUseCelsius ? language.celsius : language.fahrenheit,
      };
      if (!isEqual(tempUseCelsius, useCelsius)) {
        setUseCelsius(tempUseCelsius);
      }
    }
  }, [useCelsiusLoading]);

  const temperatureOptions = [
    { value: false, label: language.fahrenheit },
    { value: true, label: language.celsius },
  ];

  const tabChangeAction = () => {
    const tabs = document.getElementsByClassName('react-tabs__tab');
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i]) {
        tabs[i].style.backgroundColor = '';
      }
    }
    const selectedTabs = document.getElementsByClassName('react-tabs__tab--selected');
    for (let i = 0; i < tabs.length; i++) {
      if (selectedTabs[i]) {
        selectedTabs[i].style.color = theme.textColor;
        selectedTabs[i].style.backgroundColor = theme.gridButtonBackgroundColor;
      }
    }
  };

  useEffect(() => {
    tabChangeAction();
  }, []);

  useEffect(() => {
    tabChangeAction();
  }, [currentTab]);

  // const waterOptions = [
  //   { value: 'gallons', label: language.gallons },
  //   { value: 'liters', label: language.liters },
  // ];

  // const themeOptions = [
  //   { value: 'light', label: language.light },
  //   { value: 'dark', label: language.dark },
  // ];

  // const autoChooseOptions = [
  //   { value: 'no', label: language.no },
  //   { value: 'yes', label: language.yes },
  // ];

  // const defaultAutoChoose = { value: 'no', label: language.no };

  const generateLanguageOptions = () => {
    const temp = [];
    Object.keys(Languages).forEach((item) => {
      temp.push({ value: item, label: `${item.charAt(0).toUpperCase() + item.slice(1)}` });
    });
    return temp;
  };

  if (!languageOptions) {
    languageOptions = generateLanguageOptions();
  }

  // useEffect(() => {
  //   if (initRequestsComplete) {
  //     setTimeout(() => {
  //       setRenderLoader(false);
  //     }, 2000);
  //   }
  // }, [initRequestsComplete]);

  return (
    <div css={css`width: 100%; height: 100vh; max-height: 100vh; overflow: hidden;`}>
      <div css={styles.background}>
        <Tabs
          defaultIndex={0}
          css={css`width: 100%;`}
          onSelect={(index) => {
            if (index === 0 || (props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole))) {
              setCurrentTab(index);
            }
          }}
        >
          <TabList>
            <Tab><h2>{language.general}</h2></Tab>
            {/* {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole)
              && <Tab><h2>{language.settingsScreen.gunModels}</h2></Tab>}
            {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole)
              && <Tab><h2>{language.settingsScreen.autoTrailConfig}</h2></Tab>}
            {props.auth?.user?.roles && props.auth?.user?.roles.includes(mapEditRole)
            && <Tab><h2>{language.settingsScreen.zones}</h2></Tab>} */}
            {/* <Tab><h2>KLiK</h2></Tab>
            <Tab><h2>Tracker</h2></Tab> */}
          </TabList>
          <TabPanel>
            {/* <div css={styles.selectContainer}>
              <div css={styles.selectLabel}>{language.settingsScreen.language}: </div>
              <Select
                value={
                  {
                    value: props.settings?.settings?.language,
                    label: `${props.settings?.settings?.language.charAt(0).toUpperCase() + props.settings?.settings?.language.slice(1)}`,
                  }
                }
                onChange={(selection) => { props.updateSettings({ userId: props.settings?.settings?.userId, language: selection.value }); }}
                options={languageOptions}
                styles={settingsSelectStyles}
              />
            </div> */}
            <div css={styles.selectContainer}>
              <div css={styles.selectLabel}>{language.temperature}: </div>
              <Select
                value={useCelsius}
                onChange={async (selection) => {
                  setUseCelsiusLoading(true);
                  await props.setDashboardUnits(selection?.value);
                  setTimeout(() => {
                    setUseCelsiusLoading(false);
                  }, 2000);
                }}
                options={temperatureOptions}
                styles={settingsSelectStyles}
                menuPortalTarget={document.getElementById('portalPlaceholder')}
                menuPosition="absolute"
                menuPlacement="bottom"
                theme={theme.selectTheme}
              />
              <div css={css`display: ${useCelsiusLoading ? 'block' : 'none'}; position: absolute; height: 50px; width: 284px; left: 0px; overflow: hidden; border-radius: 4px; overflow: hidden;`}>
                <div css={css`position: relative; width: 100%; height: 100%;`}>
                  <LoadingIndicator visible={!!useCelsiusLoading} zIndex={4} height="48px" />
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

// For further micro customization see:
// https://react-select.com/components
const settingsSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: '#9e9e9e',
    minHeight: '28px',
    maxHeight: '28px',
    height: '28px',
    width: '177px',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '24px',
    padding: '0 6px',
    cursor: 'pointer',
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '24px',
    width: '20px',
    padding: '0',
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    width: '20px',
    padding: '10px 0',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 999,
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 'fit-content',
    minWidth: '177px',
  }),
};

const stylesFromTheme = (theme) => {
  return {
    background: css`
      padding: 10px;
      display: flex;
      width: 100%;
      height: 100%;
      color: ${theme.textColor};
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-left: 18px;
      }
    `,
    input: css`
      border: 0;
    `,
    qualitySettingsBox: css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-style: solid;
      border-color: black;
      border-width: thin;
      border-radius: 3px;
      padding: 2px;
      margin-right: 5px;
      margin-bottom: 5px;
    `,
    stageInput: css`
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 3px;
      margin-right: 3px;
      padding: 5px;
      border-style: solid;
      border-color: grey;
      border-width: thin;
      border-radius: 3px;
    `,
    overrideInput: css`
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      margin-left: 3px;
      margin-right: 3px;
      padding: 7px;
      width: 90px;
      border-style: solid;
      border-color: grey;
      border-width: thin;
      border-radius: 3px;
    `,
    inputField: css`
      width: 50px;
      margin-bottom: 4px;
      margin-right: 2px;
    `,
    qualityTableContainer: css`
      display: flex;
      flex-direction: row;
    `,
    buttonActive: css`
      display: flex;
      padding: 8px;
      background-color: #457DC1;
      cursor: pointer;
      border-radius: 3px;
      color: white;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-left: 6px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    `,
    submitButton: css`
      background-color:#7B7B7B;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      color: white;
      margin-top: 4px;
      padding: 3px 5px 3px 5px;
      border-color: black;
      border-style: solid;
      border-width: 1px;
      margin-left: 6px;
      margin-top: 3px;
      font-size: 14px;
      font-family: Open Sans;
      cursor: pointer;
      border-radius: 4px;
      // width: 90px;
      height: 35px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    `,
    resetButton: css`
      display: flex;
      padding: 8px;
      background-color: #A5A5A5;
      cursor: pointer;
      border-radius: 3px;
      color: white;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-left: 6px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    `,
    buttonGroup: css`
      display: flex;
      flex-direction: row;
      margin-left: 4px;
      margin-right: 4px;
      align-items: center;
    `,
    selectLabel: css`
      margin-left: 4px;
      margin-right: 4px;
      width: 95px;
    `,
    selectContainer: css`
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: fit-content;
      margin-top: 10px;
      margin-bottom: 5px;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
    `,
    qualityHeader: css`
      margin-bottom: 3px;
      font-style: bold;
    `,
    tabPanel: css`
      display: flex;
      margin-right: 5px;
      margin-top: 5px;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100vh - 68px);
    `,
    zonesTabPanel: css`
      display: flex;
      margin-right: 5px;
      margin-top: 5px;
      flex-wrap: wrap;
      max-width: 100%;
      height: calc(100vh - 131px);
    `,
    btnGroupHeader: css`
      display: flex;
      align-self: center;
      justify-content: center;
      flex-direction: column;
    `,
    btnGroupTitle: css`
      display: flex;
      align-self: center;
    `,
    trendsStat: css`
      display: flex;
      align-items: left;
      flex: 1;
      flex-wrap: wrap;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: 3px;
      margin-bottom: 3px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-left: 20px;
      }
    `,
    loaderContainer: css`
      position: absolute;
      top: 60;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;
    `,
    textContainer: css`
      position: absolute;
      top: 60;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;
    `,
    spinningLoader: css`
      width: 50px;
      height: 50px;
      border: 5px solid rgba(10, 188, 201, 0.4);
      border-left-color: rgb(10, 188, 201);
      border-radius: 50%;
      background: transparent;
      animation-name: rotate-s-loader;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      animation-timing-function: linear;
      position: relative;
      @keyframes rotate-s-loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

SettingsScreen.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      roles: PropTypes.string,
    }),
  }).isRequired,

  settings: PropTypes.shape({
    dashboardUseCelsius: PropTypes.bool,
    // settings: PropTypes.shape({
    //   userId: PropTypes.number,
    //   theme: PropTypes.string,
    //   language: PropTypes.string,
    //   seasonStart: PropTypes.string,
    //   useCelsius: PropTypes.bool,
    // }),
    // gunModels: PropTypes.shape({}),
  }).isRequired,
  setDashboardUnits: PropTypes.func.isRequired,
  // navbarClosed: PropTypes.bool.isRequired,
};

SettingsScreen.defaultProps = {
  // zones: {
  //   flowZones: {},
  //   temperatureZones: {},
  //   pressureZones: {},
  // },
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setDashboardUnits,
  // updateSettings,
  // fetchAllGuns,
  // fetchAllTrails,
  // fetchAllAutoTrails,
  // fetchAllAutoTrailsIOValues,
  // updateGunIsAvailable,
  // updateGun,
  // updateGunModel,
  // addGun,
  // addGunModel,
  // deleteGunModel,
  // setAutoTrailValue,
  // fetchFlowZones,
  // fetchPressureZones,
  // fetchTemperatureZones,
  // addZone,
  // deleteZone,
  // editZone,
  // editZoneIOValue,
  // fetchAllIOValues,
  // fetchAllTrackerIOValues,
  // fetchAllZoneIOValues,
})(SettingsScreen);
