import React, { useContext } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { NavLink, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import {
  FaCog,
  FaSquare,
  // FaInfoCircle,
  FaSignOutAlt,
  FaChevronLeft, FaChevronRight,
} from 'react-icons/fa';

import { Version } from 'isno/lib/components/displays';
import logo from '../static/fairbanksLogo.svg';
import { signoutUser, setNavState } from '../actions';

const squareIcon = () => (<FaSquare css={css`transform: rotate(45deg);`} />);

const NavBarSide = (props) => {
  const theme = useTheme();
  const styles = stylesFromTheme(theme, props);
  const { language } = useContext(LanguageContext);

  // let fiveSecondIntervalId;
  // const fiveSecondInterval = () => {
  //   fiveSecondIntervalId = setTimeout(() => {
  //     fiveSecondIntervalId = setTimeout(fiveSecondInterval, 5000);
  //   }, 5000);
  // };

  // useEffect(() => {
  //   props.fetchNavBarStats();
  //   fiveSecondInterval();
  //   return () => {
  //     clearTimeout(fiveSecondIntervalId);
  //   };
  // }, []);

  return (
    <div id="sidebar" css={[styles.container, props.navbarClosed ? styles.closed : styles.open]}>
      <button
        type="button"
        css={[styles.hamburger, props.navbarClosed ? styles.hamburgerClosed : styles.hamburgerOpen]}
        onClick={() => {
          props.setNavState(!props.navbarClosed);
          window.dispatchEvent(new Event('resize'));
        }}
      >
        {props.navbarClosed
          ? <FaChevronRight />
          : <FaChevronLeft />}
      </button>

      <div css={[styles.content, props.navbarClosed ? styles.closedContent : styles.openContent]}>

        <div css={styles.mountainInfo}>
          <img alt="iSno" src={logo} css={styles.logoMountain} />
          {/* <p css={styles.mountainText}>Aspen<br />Dashboard</p> */}
        </div>
        {/* <StatMonitor navbarStatistics={props.navbarStatistics} /> */}

        <div css={styles.navItems}>
          <div css={[styles.navItemsTopGroup, styles.hideScrollbar]}>
            <Link
              exact
              to="/overview"
              color={theme.textColorNavbar}
              hovercolor={theme.linkColorActive}
              activeStyle={{
                color: theme.linkColorActive,
              }}
            >
              <NavItem>
                <Route exact
                  path="/overview"
                  component={squareIcon}
                />
                <NavItemText>Overview</NavItemText>
              </NavItem>
            </Link>
            <Link
              exact
              to="/jiminy"
              color={theme.textColorNavbar}
              hovercolor={theme.linkColorActive}
              activeStyle={{
                color: theme.linkColorActive,
              }}
            >
              <NavItem>
                <Route exact
                  path="/jiminy"
                  component={squareIcon}
                />
                <NavItemText>Jiminy Peak</NavItemText>
              </NavItem>
            </Link>
            <Link
              exact
              to="/bromley"
              color={theme.textColorNavbar}
              hovercolor={theme.linkColorActive}
              activeStyle={{
                color: theme.linkColorActive,
              }}
            >
              <NavItem>
                <Route exact
                  path="/bromley"
                  component={squareIcon}
                />
                <NavItemText>Bromley</NavItemText>
              </NavItem>
            </Link>
            <Link
              exact
              to="/cranmore"
              color={theme.textColorNavbar}
              hovercolor={theme.linkColorActive}
              activeStyle={{
                color: theme.linkColorActive,
              }}
            >
              <NavItem>
                <Route exact
                  path="/cranmore"
                  component={squareIcon}
                />
                <NavItemText>Cranmore</NavItemText>
              </NavItem>
            </Link>
            <Link
              exact
              to="/trends"
              color={theme.textColorNavbar}
              hovercolor={theme.linkColorActive}
              activeStyle={{
                color: theme.linkColorActive,
              }}
            >
              <NavItem>
                <Route exact
                  path="/trends"
                  component={squareIcon}
                />
                <NavItemText>{language.trends}</NavItemText>
              </NavItem>
            </Link>
          </div>
          <div css={styles.navItemsBottomGroup}>
            <div css={styles.horzDivider} />
            <Link
              exact
              to="/settings"
              color={theme.textColorNavbar}
              hovercolor={theme.altLinkColorActive}
              activeStyle={{
                color: theme.altLinkColorActive,
              }}
            >
              <NavItem>
                <FaCog />
                <NavItemText>{language.settings}</NavItemText>
              </NavItem>
            </Link>
            <Link
              exact
              to="/"
              onClick={() => { props.signoutUser(); }}
              color={theme.textColorNavbar}
              hovercolor={theme.altLinkColorActive}
            >
              <NavItem>
                <FaSignOutAlt />
                <NavItemText>{language.logout}</NavItemText>
              </NavItem>
            </Link>
            <div css={styles.navItemsVersion}>
              <Version themeOverride={props.themeOverride} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

const stylesFromTheme = (theme, props) => {
  return {
    container: css`
      z-index: 5;
      overflow: hidden;
      background-color: ${theme.navbarBackgroundColor};
      height: 100vh;
      flex: 0 0 160px;
      display: flex;
      flex-direction: column;
      position: ${props?.float ? 'fixed' : 'sticky'};
      top:0;
      left:0;

      transition: flex-basis ease 0.4s, width ease 0.4s, padding ease 0.4s, background-color ease 1s; 
      > *:not(:first-of-type) {
        transition: opacity 0.4s ease; 
      }

      // using flex-basis not width
      width: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        position: fixed;
        height: 100%;
      }
    `,
    closed: css`
      flex-basis: 16px; 
      > *:not(:first-of-type) {
        opacity: 0;
        pointer-events: none; 
      }
      width: ${props?.float ? '18px' : ''};
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        width: 18px;
      }
      padding-right: 0px;
    `,
    open: css`
      width: ${props?.float ? '160px' : ''};
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        width: 160px;
        padding-right: 0px;
      }
      padding-right: 160px;
    `,
    hamburger: css`
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${theme.borderColor};

      cursor: pointer;

      box-shadow: 0 0 4px black;

      background: white;
      width: 24px;
      height: 24px;
      border-radius: 12px;

      position: fixed;
      top: 55px;
      transition: left ease 0.4s; 
      :hover{
        box-shadow: 0 0 6px black;
      }
    `,
    hamburgerOpen: css`
      left: 144px;
    `,
    hamburgerClosed: css`
      left: 2px;
    `,
    content: css`
      flex:1;
      // overflow-y: auto;  /*adds scroll to this container*/
      // overflow: hidden;
      display: flex;
      flex-direction:column;
      width:160px;
      transition: opacity ease 0.4s; 
    `,
    closedContent: css`
      opacity: 0.0;
      pointer-events:none;
    `,
    openContent: css`
      opacity: 1.0;
    `,
    mountainInfo: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      margin-top: 12px;
    `,
    logoMountain: css`
      height: auto;
      width: 90%;
      margin: auto;
    `,
    mountainText: css`
      color: white;
      font-size: 110%;
      text-align: center;
      white-space: nowrap;
      margin-bottom: 15px;
    `,
    navItems: css`
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `,
    navItemsTopGroup: css`
      flex:1;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 117px - 142px); // heights of our other navbar items
      overflow-y: scroll;
      overfloy-x: hidden;
  `,
    navItemsVersion: css`
      flex:1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 10px;
  `,
    navItemsBottomGroup: css`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
  `,
    hidden: css`
      display: none;
  `,
    hideScrollbar: css`
      overflow-y: scroll;
      // -ms-overflow-style: none;  /* Internet Explorer 10+ */
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
  `,
    horzDivider: css`
      height: 1px;
      width: 100%;
      background: white;
      margin: 1px 0px;
      padding: 0px 10px;
  `,
  };
};

const Link = styled(NavLink)`
  color: ${(props) => props.color || 'white'};
  padding: 12px 0px;
  :not(:last-child) {
    border-bottom: 1px solid #00000030;
  }

  :hover {
    color: ${(props) => props.hovercolor}
  }
`;

// const ExternalLink = styled.a`
//   color: ${(props) => props.color || 'white'};
//   padding: 12px 0px;
//   :not(:last-child) {
//     border-bottom: 1px solid #00000030;
//   }

//   :hover {
//     color: ${(props) => props.hovercolor}
//   }
// `;

const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

const NavItemText = styled.p`
  margin-left: 10px;
`;

NavBarSide.propTypes = {
  signoutUser: PropTypes.func.isRequired,
  setNavState: PropTypes.func.isRequired,
  themeOverride: PropTypes.string,
  // trails: PropTypes.shape({
  //   trails: PropTypes.object,
  //   trail: PropTypes.object,
  //   selectedTrailName: PropTypes.string,
  // }).isRequired,
  navbarClosed: PropTypes.bool.isRequired,
  // float: PropTypes.bool,
  // navbarStatistics: PropTypes.shape({
  //   gunsOn: PropTypes.number,
  //   gunsWaiting: PropTypes.number,
  //   gpm: PropTypes.number,
  //   cfm: PropTypes.number,
  // }).isRequired,
  // fetchNavBarStats: PropTypes.func.isRequired,
};

NavBarSide.defaultProps = {
  themeOverride: undefined,
  // float: false,
};

const mapStateToProps = (state) => ({
  // trails: state.trails,
  navbarClosed: state.nav.navbarClosed,
  navbarStatistics: state.nav.navbarStatistics,
});

export default connect(mapStateToProps, { signoutUser, setNavState })(NavBarSide);
